//Header.js
import React from 'react'
import { useEffect, useState } from 'react'

import HeaderSlider from './HeaderSlider'

function HeaderHome() {
    const [imagePaths, setImagePaths] = useState([])

    useEffect(() => {
        function importAll(r) {
            let images = {}
            r.keys().map((item, index) => {
                images[item.replace('./', '')] = r(item)
            })
            return images
        }
        const images = importAll(require.context('../images/headers/home', true, /\.(gif|jpe?g|svg|webp|JPE?G)$/))
        const imagePathsTemp = Object.keys(images).map((el) => {
            return images[el]
        })

        setImagePaths(imagePathsTemp)
    }, [])

    // console.log(imagePaths)

    return (
        <>
            <HeaderSlider imagePaths={imagePaths} />
        </>
    )
}

export default HeaderHome
