//Home.js
import React from 'react'

import HeaderHome from './HeaderHome'

function Home() {
    return (
        <>
            <HeaderHome />

            <div className="block-background">
                <div className="block-wrapper">
                    <h1>Красный Полумесяц и международное гуманитарное право</h1>

                    <p className='redline'> 
                        В 1993 году Республика Казахстан присоединилась к Женевским конвенциям 1949 года "О защите жертв войны" и двум Дополнительным протоколам
                        к ним 1977 года, а в 2009 году - к Третьему Дополнительному протоколу 2005 года. Этим шагом Казахстан подтвердил свое намерение в случае
                        вооруженного конфликта соблюдать нормы и правила, закрепленные в Конвенциях, а в мирное время распространять знания о Конвенциях среди
                        населения нашего государства.
                    </p>

                    <p className='redline'>
                        Национальные Общества Красного Креста и Красного Полумесяца всегда действуют в поддержку своих государств. К такой поддержке относится и
                        деятельность Национальных Обществ по распространению знаний о международном гуманитарном праве (МГП), содержащимся в Женевских конвенция
                        и ряде других международно-правовых актах. Такую же деятельность осуществляет и Общество Красного Полумесяца Казахстана. Она заключается
                        в проведении учебных и информационных сессий: семинаров, лекций, тренингов, учений, летних образовательных лагерей и др., а также
                        создании новых инструментов по распространению знаний. Именно поэтому Красный Полумесяц Казахстана регулярно разрабатывает и издаёт
                        обучающие материалы для различных целевых групп, где гуманитарное право изложено в интересной форме, простым и понятным языком. Основной
                        целевой группой являются сотрудники и волонтеры Общества, которые после обучения сами распространяют знания о МГП среди других целевых
                        групп - представителей властей, силовых структур, правоохранительных органов, юристов, журналистов, преподавателей и учащихся всех видов
                        организаций образования...
                    </p>

                    <p className='redline'>
                        Настоящий ресурс предназначен не только для персонала Национального Общества Казахстана, но и для всех целевых групп, где можно в
                        популярной форме ознакомиться и повысить знания по основным ракурсам МГП, узнать о Международном Движении КК и КП, его Основополагающих
                        принципах. Все пользователи также смогут повысить свои знания.
                    </p>
                </div>
            </div>
        </>
    )
}

export default Home
