//HeaderSlider.js
import React from 'react'

import { v4 } from 'uuid'

import './HeaderSlider.scss'

//swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, EffectFade } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/bundle'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

function HeaderSlider({ imagePaths }) {
    return (
        <Swiper
            spaceBetween={100}
            slidesPerView={1}
            className="swiper-bg"
            modules={[Autoplay, EffectFade]}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            loop={true}
            effect={'fade'}
        >
            {imagePaths.map((el) => {
                // console.log(el)

                return (
                    <SwiperSlide key={v4()}>
                        <div style={{ backgroundImage: `url(${el})`, color: 'red' }} loading="lazy" key={v4()} className="slide-image"></div>
                    </SwiperSlide>
                )
            })}
        </Swiper>
    )
}

export default HeaderSlider
