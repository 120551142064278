//FormAsk.js
import React from 'react'
import './FormAsk.scss'
import './Materials.scss'

import { useContext, useEffect, useState } from 'react'
import PopupContext from '../context/popupContext'

function FormAsk() {
    const [name, setName] = useState()
    const [number, setNumber] = useState()
    const [question, setQuestion] = useState()

    const { currentPopup, setCurrentPopup } = useContext(PopupContext)
    const popupContent = <FormAsk />

    function nameChangeHandler(text) {
        setName(text)
    }

    function phoneChangeHandler(phone) {
        setNumber(phone)
    }

    function questionChangeHandler(text) {
        setQuestion(text)
    }

    const handleBackdropClick = () => {
        setCurrentPopup({ content: currentPopup.content, isActive: false })
        setTimeout(() => {
            setCurrentPopup({ content: '', isActive: false })
        }, 300)
    }

    function onSubmitHandler(evt){
        evt.preventDefault()

        console.log(name, question, number)
        handleBackdropClick()
    }

    return (
        <form className='form-ask' onSubmit={(evt) => onSubmitHandler(evt)}>
            <div className="input-container">
                <label htmlFor="name">
                    Ваше имя
                    <input type="text" id="name" name="name" onChange={(evt) => nameChangeHandler(evt.target.value)} />
                </label>
            </div>

            <div className="input-container">
                <label htmlFor="phone">
                    Ваш номер телефона
                    <input type="tel" id="phone" name="phone" onChange={(evt) => phoneChangeHandler(evt.target.value)} required />
                </label>
            </div>
            

            <div className="input-container">
                <label htmlFor="question">
                    Ваш вопрос
                    <input type="textarea" id="question" name="question" onChange={(evt) => questionChangeHandler(evt.target.value)} />
                </label>
            </div>

            <button type="submit" className="materials-controls__button materials-controls__button--active">
                Задать вопрос
            </button>

            <div className='thin-text'>*Отправляя эту форму, вы соглашаетесь с нашей политикой обработки конфиденциальных данных</div>
        </form>
    )
}

export default FormAsk
