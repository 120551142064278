//Materials.js
import React from 'react'

import { Link } from 'react-router-dom'

import lessons from '../data/lessons.json'

import './Materials.scss'

function Materials() {
    return (
        <div className="block-background">
            <div className="block-wrapper">
                {/* <h1>Lorem ipsum</h1>
                <p className='materials-description'>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatum facilis sint quam culpa? Cumque deserunt, quidem pariatur earum alias,
                    et quibusdam dolore laboriosam dicta odio, exercitationem repellendus at saepe aperiam?
                </p> */}

                <div className="materials-container">
                    {lessons.map((el) => {
                        return (
                            <div className={`material-card material-card--${el.link}`}>
                                <div className='material-card__inner'>
                                    <h2>{el.name}</h2>
                                    <p className='material-card__description'>{el.description}</p>

                                    <Link to={el.link} className="material-card__link">
                                        Смотреть материалы
                                    </Link>
                                </div>

                                <div className="material-card__image"></div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Materials
