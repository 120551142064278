import React from 'react'
import { NavLink } from 'react-router-dom'
import './Menu.scss'
import { useContext, useEffect, useState } from 'react'
import PopupContext from '../context/popupContext'
import { HashLink } from 'react-router-hash-link'
import logoRuDark from '../images/logo-ru-dark.svg'
import logoRuWhite from '../images/logo-ru-white.svg'
import { useTranslation } from 'react-i18next'

import FormAsk from './FormAsk'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'

function Menu() {
    const { t, i18n } = useTranslation('global')
    const { currentPopup, setCurrentPopup } = useContext(PopupContext)
    const popupContent = <FormAsk />

    const [isSticky, setIsSticky] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerWidth >= 768) {
                setIsSticky(window.scrollY > 0)
            } else {
                setIsSticky(false)
            }
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const handleBackdropClick = () => {
        setCurrentPopup({ content: currentPopup.content, isActive: false })
        setTimeout(() => {
            setCurrentPopup({ content: '', isActive: false })
        }, 300)
    }

    const changeLang = (lang) => {
        i18n.changeLanguage(lang)
    }

    return (
        <nav className={`${isSticky ? 'sticky-menu' : ''} ${isMobile ? 'mobile' : ''}`}>

            <NavLink className={({ isActive }) => (isActive ? 'activeLink' : 'link')} to="/" end onClick={handleBackdropClick}>
                {isBrowser ? (
                    <img src={logoRuDark} alt="Company logo" className="menu__company-logo" />
                ) : (
                    <img src={logoRuWhite} alt="Company logo" className="menu__company-logo" />
                )}
            </NavLink>

            <div className={`main-links  ${isMobile ? 'mobile' : ''}`}>
                <NavLink className={({ isActive }) => (isActive ? 'activeLink' : 'link')} to="/" end onClick={handleBackdropClick}>
                    Главная
                </NavLink>

                <div className="dropdown">
                    <NavLink
                        to="/materials"
                        className={({ isActive }) => (isActive ? 'activeLink dropdown-toggle' : 'dropdown-toggle')}
                        onClick={isMobile ? handleBackdropClick : ''}
                    >
                        Материалы
                    </NavLink>

                    {isBrowser ? (
                        <ul className="dropdown-menu">
                            <li>
                                <NavLink to="/materials/ihl" onClick={handleBackdropClick}>
                                    Международное гуманитарное право
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/materials/icrc" onClick={handleBackdropClick}>
                                    Международное движение КК и КП
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/materials/redcrescentkz" onClick={handleBackdropClick}>
                                    Общество Красного Полумесяца РК
                                </NavLink>
                            </li>
                        </ul>
                    ) : (
                        ''
                    )}
                </div>

                <NavLink to="https://donation-rc.dot-agency.kz/" className={({ isActive }) => (isActive ? 'activeLink' : 'link')} onClick={handleBackdropClick}>
                    Пожертвовать
                </NavLink>

                <NavLink className={'link'} onClick={() => setCurrentPopup({ content: popupContent, isActive: true })}>
                    Задать вопрос
                </NavLink>
            </div>

            {/* <div className="languages-list">
                <button
                    onClick={(evt) => {
                        evt.preventDefault()
                        changeLang('ru')
                        handleBackdropClick()
                    }}
                    className={`language-list__item ${i18n.language === 'ru' ? 'language-list__item--active' : ''}`}
                >
                    ru
                </button>
                <button
                    onClick={(evt) => {
                        evt.preventDefault()
                        changeLang('kz')
                        handleBackdropClick()
                    }}
                    className={`language-list__item ${i18n.language === 'kz' ? 'language-list__item--active' : ''}`}
                >
                    kz
                </button>
                <button
                    onClick={(evt) => {
                        evt.preventDefault()
                        changeLang('en')
                        handleBackdropClick()
                    }}
                    className={`language-list__item ${i18n.language === 'en' ? 'language-list__item--active' : ''}`}
                >
                    en
                </button>
            </div> */}
        </nav>
    )
}

export default Menu
