import React from 'react'

import { Link } from 'react-router-dom'
import logoRuWhite from '../images/logo-ru-white.svg'

import './Footer.scss'

function Footer() {
    return (
        <footer>
            <div className="footer-section footer-section--logo">
                <Link to="https://redcrescent.kz/">
                    <img src={logoRuWhite} className="footer__logo" />
                </Link>

                <p className='footer__copyright'>© 2016-2024 Красный Полумесяц Казахстана. Все права защищены.</p>
            </div>

            <div className="footer-section">
                <h4>Полезные ссылки</h4>
                <ul className="list-style-block">
                    <li>
                        <Link to="https://redcrescent.kz/ru/reagirovanie-na-bedstviya/" target="_blank" rel="nofollow">
                            Реагирование на бедствия
                        </Link>
                    </li>
                    <li>
                        <Link to="https://redcrescent.kz/ru/soc-help/" target="_blank" rel="nofollow">
                            Социальная помощь
                        </Link>
                    </li>
                    <li>
                        <Link to="http://donor.redcrescent.kz/" target="_blank" rel="nofollow">
                            Донорство крови
                        </Link>
                    </li>
                    <li>
                        <Link to="https://redcrescent.kz/ru/nashi-kursy/kursy-po-podgotovke-k-zemletryaseniyu/" target="_blank" rel="nofollow">
                            Курсы по подготовке к землетрясению
                        </Link>
                    </li>
                    <li>
                        <Link to="https://redcrescent.kz/ru/nashi-kursy/pervaya-dovrachebnaya-pomoshh/" target="_blank" rel="nofollow">
                            Обучение первой помощи
                        </Link>
                    </li>
                    <li>
                        <Link to="https://redcrescent.kz/ru/pomoshh-detyam-sirotam/" target="_blank" rel="nofollow">
                            Помощь детям сиротам
                        </Link>
                    </li>
                    <li>
                        <Link to="https://redcrescent.kz/ru/sluzhba-rozyska/" target="_blank" rel="nofollow">
                            Служба розыска
                        </Link>
                    </li>
                    <li>
                        <Link to="https://redcrescent.kz/ru/volunteers/" target="_blank" rel="nofollow">
                            Волонтёры
                        </Link>
                    </li>
                </ul>
            </div>

            <div className="footer-section footer-section--contacts">
                <h4>Наши контакты</h4>
                <ul>
                    <li>
                        <a href="mailto:info@redcrescent.kz">info@redcrescent.kz</a>
                    </li>
                    <li>
                        <a href="tel:+77272367623">+7 (727) 236 76 23</a>
                    </li>
                </ul>

                
            </div>
        </footer>
    )
}

export default Footer
