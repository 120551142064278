//MaterialItem.js
import React, { useEffect } from 'react'

import { useState } from 'react'
import { useParams } from 'react-router-dom'
import lessons from '../data/lessons.json'

import { Link } from 'react-router-dom'

import { IoPlayOutline } from 'react-icons/io5'
import { MdOutlineFileDownload } from 'react-icons/md'

import HeaderSlider from './HeaderSlider'
import Status from './Status'

import { v4 } from 'uuid'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'

function MaterialItem() {
    const { title } = useParams()

    const [currentData, setCurrentData] = useState('')

    const [currentType, setCurrentType] = useState('video')

    useEffect(() => {
        let newData = lessons.filter((el) => (el.link === title ? el : ''))

        setCurrentData(newData[0])
    }, [title])

    function setCurrentTypeHandler(newType) {
        setCurrentType(newType)
    }

    const [imagePaths, setImagePaths] = useState([])

    useEffect(() => {
        function importAll(r) {
            let images = {}
            r.keys().map((item, index) => {
                images[item.replace('./', '')] = r(item)
            })
            return images
        }
        const images = importAll(require.context('../images/headers/home', true, /\.(gif|jpe?g|svg|webp|JPE?G)$/))
        const imagePathsTemp = Object.keys(images).map((el) => {
            return images[el]
        })

        setImagePaths(imagePathsTemp)
    }, [])

    return (
        <div className="block-background">
            <div className="block-wrapper">
                <HeaderSlider imagePaths={imagePaths} />

                <div className="materials-page">
                    <div className="materials-lessons">
                        <h1>{currentData.name}</h1>
                        <p>{currentData.description}</p>

                        <br />
                        {currentData.video !== undefined && currentType === 'video'
                            ? currentData.video.map((el) => {
                                  return (
                                      <div className="category-item" key={v4()}>
                                          <h2>{el.categoryName}</h2>

                                          <ul className="material-list">
                                              {el.items.map((el, index) => {
                                                  //   console.log(el)

                                                  return (
                                                      <li className="material-list__item" key={v4()}>
                                                          { <div className="material-list__index">{isBrowser ? `Урок №${index + 1}`: (index + 1)}</div> }
                                                          <div className="material-list__name">{el.name}</div>

                                                          <div className="material-list__links">
                                                              <Link
                                                                  to={`/materials/${title}/video`}
                                                                  state={{ lessonData: el }} // Передача URL видео через state
                                                              >
                                                                  <IoPlayOutline />
                                                              </Link>
                                                          </div>
                                                      </li>
                                                  )
                                              })}
                                          </ul>
                                      </div>
                                  )
                              })
                            : ''}

                        {currentData.presentation !== undefined && currentType === 'presentation'
                            ? currentData.presentation.map((el) => {
                                  return (
                                      <div className="category-item" key={v4()}>
                                          <h2>{el.categoryName}</h2>

                                          <ul className="material-list">
                                              {el.items.map((elInner, index) => {
                                                  return (
                                                      <li className="material-list__item" key={v4()}>
                                                          <div className="material-list__name">{elInner.name}</div>

                                                          <div className="material-list__links">
                                                              <Link to={elInner.link}>
                                                                  {el.type === 'movies' ? <IoPlayOutline /> : ''}
                                                                  {el.type === 'files' ? <MdOutlineFileDownload /> : ''}
                                                              </Link>
                                                          </div>
                                                      </li>
                                                  )
                                              })}
                                          </ul>
                                      </div>
                                  )
                              })
                            : ''}
                    </div>

                    <div className="materials-controls">
                        <div className="materials-controls__buttons">
                            <button
                                onClick={(evt) => {
                                    setCurrentTypeHandler('video')
                                }}
                                className={`materials-controls__button ${currentType === 'video' ? 'materials-controls__button--active' : ''}`}
                            >
                                Обучающие фильмы
                            </button>
                            <button
                                onClick={(evt) => {
                                    setCurrentTypeHandler('presentation')
                                }}
                                className={`materials-controls__button ${currentType === 'presentation' ? 'materials-controls__button--active' : ''}`}
                            >
                                Методические материалы
                            </button>
                        </div>

                        {/* <Status /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MaterialItem
