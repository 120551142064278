import React from 'react'
import { useLocation } from 'react-router-dom'

function VideoItem() {
    const location = useLocation()
    const { lessonData } = location.state || {}

    console.log(lessonData)

    return (
        <div className="block-background">
            <div className="block-wrapper">
                {lessonData ? (
                    <div>
                        <h1>{lessonData.name}</h1>
                        <br />
                        <iframe
                            width="560"
                            height="315"
                            src={lessonData.link}
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen
                        ></iframe>
                    </div>
                ) : (
                    <p>Ожидание данных</p>
                )}
<br />
                <div>
                    <h2>Правила прохождения теста и получения сертификата:</h2>
                    <p className='redline'>Каждый тест состоит из 10 вопросов, расположенных в случайном порядке. Минимальный проходной балл составляет 75% от общего количества баллов. Сертификат выдается при положительном (75-100%) результате тестирования.
                    Время прохождения теста не ограниченно. Количество попыток не ограниченно.</p>
                </div>
            </div>
        </div>
    )
}

export default VideoItem
